(function($) {
    "use strict";   
    $(document).ready(function(){
      

        /* -------------------------------------------------
            menu 
        ------------------------------------------------- */
        
        $(document).on('click', '.menu-bar', function(event) {
            $(".ba-navbar").toggleClass("ba-navbar-show", "linear");
        });
        $(document).on('click', 'body', function(event) {
            if (!$(event.target).closest('.menu-bar').length && !$(event.target).closest('.ba-navbar').length) {
                $('.ba-navbar').removeClass('ba-navbar-show');
            }
        });
        $(document).on('click', '.menu-close', function(event) {
            $(".ba-navbar").toggleClass("ba-navbar-show", "linear");
        });        
                        

        /* -------------------------------------------------
            add balance 
        ------------------------------------------------- */
        if ($('.ba-add-balance-btn').length) {
            $(".ba-add-balance-btn").on('click', function() {
                $(".add-balance-inner-wrap").toggleClass("add-balance-inner-wrap-show", "linear");
            });
            $('body').on('click', function(event) {
                if (!$(event.target).closest('.ba-add-balance-btn').length && !$(event.target).closest('.add-balance-inner-wrap').length) {
                    $('.add-balance-inner-wrap').removeClass('add-balance-inner-wrap-show');
                }
            });
        }

        /*------------------------------------------------
            Search Popup
        ------------------------------------------------*/
        var bodyOvrelay = $('#body-overlay');
        var searchPopup = $('#search-popup');
        var sidebarMenu = $('#sidebar-menu');

        $(document).on('click', '#body-overlay', function(e) {
            e.preventDefault();
            bodyOvrelay.removeClass('active');
            searchPopup.removeClass('active');
            sidebarMenu.removeClass('active');
        });
        $(document).on('click', '.search', function(e) {
            e.preventDefault();
            searchPopup.addClass('active');
            bodyOvrelay.addClass('active');
        });



        /* -------------------------------------------------------------
        	RoundCircle Progress js
        ------------------------------------------------------------- */
        


        // /*-----------------
        // auto notification 
        // ------------------*/
        // $('#overlay').modal('show');

        // setTimeout(function() {
        //     $('#overlay').modal('hide');
        // }, 1500);

        var myModal = document.getElementById('myModal');
        var myInput = document.getElementById('myInput');


        // /*-----------------
        // auto notification 
        // ------------------*/
        // const storageDark = localStorage.getItem('dark')
        
        // if($('body').hasClass('dark')){
        //     changeMode('true');
        // } else {
        //     changeMode('true');
        // }
        // if (storageDark !== 'null') {
        //     changeMode(storageDark)
        // }
        // jQuery(document).on("change", '.change-mode input[type="checkbox"]' ,function (e) {
        //     const dark = $(this).attr('data-active');
        //     if (dark === 'true') {
        //         $(this).attr('data-active','false')
        //     } else {
        //         $(this).attr('data-active','true')
        //     }
        //     changeMode(dark)
        // })
        function changeMode (dark) {
            const body = jQuery('body')
            if (dark === 'true') {
                // $('[data-mode="toggle"]').find('i.a-right').removeClass('ri-sun-line');
                // $('[data-mode="toggle"]').find('i.a-left').addClass('ri-moon-clear-line');
                $('#dark-mode').prop('checked', true).attr('data-active', 'false')
                $('.darkmode-logo').removeClass('d-none')
                $('.light-logo').addClass('d-none')
                body.addClass('dark')
                dark = true
            } else {
                // $('[data-mode="toggle"]').find('i.a-left').removeClass('ri-moon-clear-line');
                // $('[data-mode="toggle"]').find('i.a-right').addClass('ri-sun-line');
                $('#dark-mode').prop('checked', false).attr('data-active', 'true');
                $('.light-logo').removeClass('d-none')
                $('.darkmode-logo').addClass('d-none')
                body.removeClass('dark')
                dark = false
            }
            updateLocalStorage(dark)
            const event = new CustomEvent("ChangeColorMode", {detail: {dark: dark} });
            document.dispatchEvent(event);
        }
        function updateLocalStorage(dark) {
            localStorage.setItem('dark', dark)
        }


    });

    $(window).on('load', function() {

        /*-----------------
            preloader
        ------------------*/
        var preLoder = $("#preloader");
        preLoder.fadeOut(0);

        /*-----------------
            back to top
        ------------------*/
        var backtoTop = $('.back-to-top')
        backtoTop.fadeOut();

        /*---------------------
            Cancel Preloader
        ----------------------*/
        $(document).on('click', '.cancel-preloader a', function(e) {
            e.preventDefault();
            $("#preloader").fadeOut(2000);
        });

    });

})(jQuery);